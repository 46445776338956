<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">
      <span
        class="badge rounded-pill bx-pull-right font-size-12"
        :class="{
          'bg-success': pendientes == 0,
          'bg-danger': pendientes > 0
        }"
      >
        {{ pendientes > 0 ? 'Revisar' : 'Bien' }}
      </span>
      Soporte
    </h5>
    <div class="card-body">
      <div class="row">
        <div class="col-4 text-center">
          <router-link
            :to="{
              name: 'listadoTicketsSoporte',
              query: { solo_nuevos: true }
            }"
            title="Nuevos"
          >
            <div class="text-center">
              <div class="avatar mx-auto">
                <div class="avatar-title rounded-circle bg-danger bg-gradient font-size-20" :title="nuevos">
                  {{ nuevos <= 9999 ? nuevos : '999+' }}
                </div>
              </div>
            </div>
            <div class="text-danger text-truncate w-100">
             Nuevos 
            </div>
          </router-link>
        </div>
       <div class="col-4 text-center">
          <router-link 
            :to="{
              name: 'listadoTicketsSoporte', 
              query: {
                tod_act_comp: 'activos'
              }
            }" 
            title="Activos"
          >
            <div class="text-center">
              <div class="avatar mx-auto">
                <div class="avatar-title rounded-circle bg-warning bg-gradient font-size-20" :title="pendientes">
                  {{ pendientes <= 9999 ? pendientes : '999+'}}
                </div>
              </div>
            </div>
            <div class="text-warning text-truncate w-100">
              Activos
            </div>
          </router-link>
        </div>
        <div class="col-4 text-center">
          <router-link
            :to="{
              name: 'listadoTicketsSoporte',
              query: {
                tod_act_comp: 'todos'
              }
            }"
            title="Soportes"
          > 
            <div class="text-center">
              <div class="avatar mx-auto">
                <div class="avatar-title rounded-circle bg-primary bg-gradient font-size-20">
                  <i class="fas fa-wrench"></i>
                </div>
              </div>
            </div>
            <div class="text-truncate w-100">
              Soportes
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketSoporteSrv from '@/services/TicketSoporteSrv.js'

export default {
  name: 'WSoportes',
  data() {
    return {
      nuevos:     sessionStorage.getItem('argusblack.wsoportes.nuevos')     || 0,
      pendientes: sessionStorage.getItem('argusblack.wsoportes.pendientes') || 0,
      intervals: {
        actualizacion: null
      }
    }
  },
  created() {
    var self = this

    this.actualizar()
    this.intervals.actualizacion = setInterval(function() {
      self.actualizar()
    }, 60000)
  },
  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    actualizar() {
      var self = this
      TicketSoporteSrv.nNuevos().then(response => {
        self.nuevos = response.data
        sessionStorage.setItem('argusblack.wsoportes.nuevos', self.nuevos)
      }).catch(error => {
        let mensaje = error.response.data.message || ''
        mensaje != '' ? iu.msg.error(mensaje):null
        console.log(error)
      })

      TicketSoporteSrv.nPendientes().then(response => {
        self.pendientes = response.data
        sessionStorage.setItem('argusblack.wsoportes.pendientes', self.pendientes)
      }).catch(error => {
        let mensaje = error.response.data.message || ''
        mensaje != '' ? iu.msg.error(mensaje):null
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>