<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">
      <span class="badge bg-info rounded-pill bx-pull-right font-size-12">
        Del mes
      </span>
      Facturas
    </h5>
    <div class="card-body">
      <div class="row">
        <div class="col-4 text-center">
          <router-link
            :to="{ name: 'listadoFacturas', query: { filtros: ['pagadas'] } }"
            title="Pagadas"
          >
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-info bg-gradient font-size-20" :title="pagadas">
                {{ pagadas <= 9999 ? pagadas : '999+' }}
              </div>
            </div>
            <div class="text-info text-truncate w-100">
              Pagadas
            </div>
          </router-link>
        </div>
        <div class="col-4 text-center">
          <router-link
            :to="{
              name: 'listadoFacturas',
              query: { filtros: ['no pagadas'] }
            }"
            title="Pendientes"
          >
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-warning bg-gradient font-size-20" :title="pendientes">
                {{ pendientes <= 9999 ? pendientes : '999+' }}
              </div>
            </div>
            <div class="text-warning text-truncate w-100">
              Pendientes
            </div>
          </router-link>
        </div>
        <div class="col-4 text-center">
          <router-link
            :to="{ name: 'listadoFacturas' }"
            title="Facturas"
          >
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-primary bg-gradient font-size-20">
                <i class="mdi mdi-file-document-outline"></i>
              </div>
            </div>
            <div class="text-truncate w-100">
              Facturas
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FacturaSrv from '@/services/FacturaSrv.js'

export default {
  name: 'WFacturas',

  data() {
    return {
      pagadas:    sessionStorage.getItem('argusblack.wfacturas.pagadas')    || 0,
      pendientes: sessionStorage.getItem('argusblack.wfacturas.pendientes') || 0,
      intervals: {
        actualizacion: null
      }
    }
  },

  created() {
    var self = this

    this.actualizar()
    this.intervals.actualizacion = setInterval(function() {
      self.actualizar()
    }, 60000)
  },

  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },

  methods: {
    actualizar() {
      var self = this
      let params = { mes_actual: true }
      FacturaSrv.nPagadas(params).then(response => {
        self.pagadas = response.data
        sessionStorage.setItem('argusblack.wfacturas.pagadas', self.pagadas)
      }).catch(error => {
        let mensaje = error.response.data.message || ''
        mensaje != '' ? iu.msg.error(mensaje):null
        console.log(error)
      })

      FacturaSrv.nNoPagadas(params).then(response => {
        self.pendientes = response.data
        sessionStorage.setItem('argusblack.wfacturas.pendientes', self.pendientes)
      }).catch(error => {
        let mensaje = error.response.data.message || ''
        mensaje != '' ? iu.msg.error(mensaje):null
        console.log(error)
      })
    }
  }
};
</script>