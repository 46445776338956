<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">
      <span class="badge bg-primary rounded-pill bx-pull-right font-size-12">
        Total
      </span>
      CPEs
    </h5>
    <div class="card-body">
      <div class="row">
        <div class="col-4 text-center">
          <router-link :to="{ name: 'listadoCpes', query: { filtros: 'conectados' } }" title="Conectados">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-info bg-gradient font-size-20" :title="conectados">
                {{ conectados <= 9999 ? conectados : '999+' }}
              </div>
            </div>
            <div class="text-info text-center text-truncate w-100">
              <small>
                Conectados
              </small>
            </div>
          </router-link>
        </div>
        <div class="col-4 text-center">
          <router-link :to="{ name: 'listadoCpes', query: { filtros: 'desconectados' } }" title="Desconectados">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-danger bg-gradient font-size-20" :title="desconectados">
                {{ desconectados <= 9999 ? desconectados : '999+' }}
              </div>
            </div>
            <div class="text-danger text-truncate w-100">
              <small>
                Desconectados
              </small>
            </div>
          </router-link>
        </div>
        <div class="col-4 text-center">
          <router-link :to="{ name: 'listadoCpes' }" title="CPEs">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-primary bg-gradient font-size-20">
                <i class="mdi mdi-format-list-bulleted"></i>
              </div>
            </div>
            <div class="text-truncate w-100">
              CPEs
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CpeSrv from '@/services/CpeSrv.js'

export default {
  name: 'WCpes',

  data() {
    return {
      conectados:     sessionStorage.getItem('argusblack.wcpes.conectados')     || 0,
      desconectados:  sessionStorage.getItem('argusblack.wcpes.desconectados')  || 0,
      intervals: {
        actualizacion: null
      }
    }
  },

  created() {
    var self = this

    this.actualizar()
    this.intervals.actualizacion = setInterval(function() {
      self.actualizar()
    }, 60000)
  },

  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },

  methods: {
    actualizar() {
      var self = this
      let params = { mes_actual: true }
      CpeSrv.nEnLinea().then(response => {
        self.conectados = response.data
        sessionStorage.setItem('argusblack.wcpes.conectados', self.conectados)
      }).catch(error => {
        let mensaje = error.response.data.message || ''
        mensaje != '' ? iu.msg.error(mensaje):null
        console.log(error)
      })

      CpeSrv.nDesconectados().then(response => {
        self.desconectados = response.data
        sessionStorage.setItem(
          'argusblack.wcpes.desconectados',
          self.desconectados
        )
      }).catch(error => {
        let mensaje = error.response.data.message || ''
        mensaje != '' ? iu.msg.error(mensaje):null
        console.log(error)
      })
    }
  }
};
</script>

<style scoped>

</style>