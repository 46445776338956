import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/tickets-soporte`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(ticket) {
    return apiClient.post(`${ticket.id}`, ticket)
  },
  
  actualizarTicketCalendario(id, datos){
    return apiClient.post(`${API}/calendarios/eventos/tickets-soporte/${id}`, datos)
  },

  cancelar(idTicket) {
    return apiClient.post(`${idTicket}/cancelar`)
  },

  cantidadPendientes() {
    return apiClient.get('n-pendientes')
  },

  eliminar(id) {
    return apiClient.delete(`${id}`)
  },

  guardar(ticket) {
    return apiClient.post('', ticket)
  },

  nNuevos() {
    return apiClient.get('n-nuevos')
  },

  nPendientes() {
    return apiClient.get('n-pendientes')
  },

  ticketJSON(id) {
    return apiClient.get(`${id}.json`)
  },

  ticketsJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/tickets-soporte.json`, { params: params })
  },

  calendarioTicketsJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/calendario/tickets-soporte.json`, { params: params })
  },

  totalTicketsActivos() {
    return apiClient.get('activos/total')
  }
}
