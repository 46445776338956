<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">
      Nuevos clientes por mes
    </h5>
    <div class="card-body pb-0">
      <div id="graficaNuevosClientesPorMes">
        <apexchart
          class="apex-charts"
          height="350"
          dir="ltr"
          :series="series"
          :options="chartOptions"
        ></apexchart>
      </div>
      <p class="text-muted mb-1 text-justify">
        Esta gráfica te ayuda a ver la cantidad de clientes que has adquirido
        a lo largo del tiempo.
      </p>
      <br>
      <div class="row mb-4">
        <div class="col-md-5">
          <div class="row mb-4">
            <label class="col-sm-4 col-4 col-form-label text-truncate" title="Mes inicial">
              Mes inicial
            </label>
            <div class="col-sm-8 col-8">
              <Datepicker
                ref="mesInicial"
                v-model="mesInicial"
                class="form-control"
                :class="{
                  'bg-white': $store.state.layout.layoutMode == 'light',
                }"
                :minimumView="'month'"
                :maximumView="'month'"
                :inputFormat="'MMMM yyyy'"
              />
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="row mb-4">
            <label class="col-sm-4 col-4 col-form-label text-truncate" title="Mes final">
              Mes final
            </label>
            <div class="col-sm-8 col-8">
              <Datepicker
                ref="mesFinal"
                v-model="mesFinal"
                class="form-control"
                :class="{
                  'bg-white': $store.state.layout.layoutMode == 'light',
                }"
                :minimumView="'month'"
                :maximumView="'month'"
                :inputFormat="'MMMM yyyy'"
              />
            </div>
          </div>
        </div>
        <div class="col-md-2 col-6">
          <button
            class="btn btn-outline-success btn-block"
            @click="actualizar()"
          >
            <i class="mdi mdi-refresh"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReporteSrv from '@/services/ReporteSrv.js'
import Datepicker from 'vue3-datepicker'
import moment from 'moment'

export default {
  name: 'WClientesNuevosPorMes',
  components: {
    Datepicker
  },
  data() {
    return {
      mesInicial: '01-2021',
      mesFinal: '12-2023',
      resultados: [],

      // grafica
      series: [
        {
          name: "Cliente nuevos",
          data: [],
        }
      ],
      // configuracion de la grafica
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
          },
        },
        markers: {
          size: 4,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#57c9eb"],
        title: {
          text: "Cliente nuevos",
          align: "left",
          style: {
            fontWeight: 500,
          },
        },
        yaxis: {
          min: 0
        },
        xaxis: {
          categories: [
            // "Ene",
            // "Feb",
            // "Mar",
            // "Apr",
            // "May",
            // "Jun",
            // "Jul",
            // "Aug",
            // "Sep",
            // "Oct",
            // "Nov",
            // "Dic"
          ],
          // title: {
          //   text: "Meses",
          // },
          tickAmount: 10,
          tooltip: {
            enabled: false,
          }
        },
      }
      // fin de la configuracion de la grafica
    }
  },
  created() {
    var self = this

    let hoy = new Date()
    self.mesFinal = hoy
    var anio = hoy.getFullYear();
    var mes = hoy.getMonth();
    var dia = hoy.getDate();

    var menosAnios = 2
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) || window.innerWidth <= 780) {
      menosAnios = 1
    }
    self.mesInicial = new Date(anio - menosAnios, mes, dia);
      
    self.actualizar()

    // Actualización de la gráfica cuando se cambia el tamaño de la gráfica
    window.addEventListener('resize', function(event){
      self.dibujarGrafica(self.resultados)
    })
  },
  mounted: function() {
    iu.spinner.mostrar('#graficaNuevosClientesPorMes')
  },
  methods: {
    actualizar() {
      var self = this
      
      iu.spinner.mostrar('#graficaNuevosClientesPorMes')
      var mesInicial = self.formatoFecha(self.mesInicial,'MM/YYYY')
      var mesFinal = self.formatoFecha(self.mesFinal,'MM/YYYY')

      ReporteSrv.nuevosPorMes(mesInicial, mesFinal, true).then(response => {
        self.resultados = response.data
        self.dibujarGrafica(self.resultados)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje):null
        console.log(error)
      }).finally(fin => {
        iu.spinner.ocultar('#graficaNuevosClientesPorMes')
      })
    },
    dibujarGrafica(resultados) {
      var self = this, cont = 0

      let categories = []
      self.series[0].data = []
      
      resultados.forEach(r => {
        if(cont == 0){
          categories.push('')
          cont = 1
        } else {
          let fecha = self.formatoFecha(r.mes, 'MMM YY')
          categories.push(fecha)
          cont = 0
        }
        self.series[0].data.push(r.total)
      })

      self.chartOptions = {
        chart: {
          height: 350,
          type: "area",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
          },
        },
        markers: {
          size: 4,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#57c9eb"],
        title: {
          text: "Cliente nuevos",
          align: "left",
          style: {
            fontWeight: 500,
          },
        },
        yaxis: {
          min: 0
        },
        xaxis: {
          categories: categories,
          // title: {
          //   text: "Meses",
          // },
          tickAmount: 50,
          tooltip: {
            enabled: false,
          }
        },
      }
    },
    formatoFecha(mt, formato = 'MMMM YYYY') {
      return moment(mt).locale('es-mx').format(formato)
    }
  },
}
</script>

<style scoped>
.btn-block {
  display: block;
  width: 100%;
}
</style>