<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">
      <span class="badge bg-primary rounded-pill bx-pull-right font-size-12">
        Total
      </span>
      Clientes
    </h5>
    <div class="card-body">
      <div class="row">
        <div class="col-4 text-center">
          <router-link :to="{ name: 'listadoClientes' }" title="Activos">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-info bg-gradient font-size-20" :title="activos">
                {{ activos <= 9999 ? activos : '999+' }}
              </div>
            </div>
            <div class="text-info text-truncate w-100">
              Activos
            </div>
          </router-link>
        </div>
        <div class="col-4 text-center" v-show="suspendidos > 0">
          <a :href="clientesSuspendidos()" title="Suspendidos">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-warning bg-gradient font-size-20" :title="suspendidos">
                {{ suspendidos <= 9999 ? suspendidos : '999+' }}
              </div>
            </div>
            <div class="text-warning text-truncate w-100">
              Suspendidos
            </div>
          </a>
        </div>
        <div class="col-4 text-center" v-show="retirados > 0">
          <a :href="clientesRetirados()" title="Retirados">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-danger bg-gradient font-size-20" :title="retirados">
                {{ retirados <= 9999 ? retirados : '999+'}}
              </div>
            </div>
            <div class="text-danger text-truncate w-100">
              Retirados
            </div>
          </a>
        </div>
        <div class="col-4 text-center" v-show="(suspendidos == 0 || retirados == 0) && !(suspendidos == 0 && retirados == 0)">
          <router-link :to="{ name: 'listadoClientes' }" title="Clientes">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-primary bg-gradient font-size-20">
                <i class="mdi mdi-format-list-bulleted"></i>
              </div>
            </div>
            <div class="text-truncate w-100">
              Clientes
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClienteSrv from '@/services/ClienteSrv.js'
export default {
  name: 'WClientes',
  data() {
    return {
      activos:      sessionStorage.getItem('argusblack.wclientes.activos')      || 0,
      suspendidos:  sessionStorage.getItem('argusblack.wclientes.suspendidos')  || 0,
      retirados:    sessionStorage.getItem('argusblack.wclientes.retirados')    || 0,
      intervals: {
        actualizacion: null
      },
      baseUrl: window.location.origin,
    }
  },
  created() {
    var self = this

    this.actualizar()
    this.intervals.actualizacion = setInterval(function() {
      self.actualizar()
    }, 60000)
  },
  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    actualizar() {
      var self = this

      // Clientes activos
      ClienteSrv.cantidad({activos: true}).then(response => {
        self.activos = response.data
        sessionStorage.setItem('argusblack.wclientes.activos', self.activos)
      }).catch(error => {
        let mensaje = error.response.data.message || ''
        mensaje != '' ? iu.msg.error(mensaje):null
        console.log(error)
      })

      // Clientes suspendidos
      ClienteSrv.nSuspendidos().then(response => {
        self.suspendidos = response.data
        sessionStorage.setItem(
          'argusblack.wclientes.suspendidos',
          self.suspendidos
        )
      }).catch(error => {
        let mensaje = error.response.data.message || ''
        mensaje != '' ? iu.msg.error(mensaje):null
        console.log(error)
      })

      // Clientes retirados 
      ClienteSrv.cantidad({retirados: true}).then(response => {
        self.retirados = response.data
        sessionStorage.setItem(
          'argusblack.wclientes.retirados',
          self.retirados
        )
      }).catch(error => {
        let mensaje = error.response.data.message || ''
        mensaje != '' ? iu.msg.error(mensaje):null
        console.log(error)
      })
    },
    clientesRetirados() {
      var self = this
      let url = self.baseUrl+'/clientes?dato=&n_pag=1&n_cln_pag=10&filtros=%7B"activos"%3Afalse,"suspendidos"%3Afalse,"atrasados"%3Afalse,"retirados"%3Atrue%7D'
      return url
    },
    clientesSuspendidos() {
      var self = this
      let url = self.baseUrl+'/clientes?dato=&n_pag=1&n_cln_pag=10&filtros=%7B"activos"%3Atrue,"suspendidos"%3Atrue,"atrasados"%3Afalse,"retirados"%3Afalse%7D'
      return url
    }
  }
}
</script>